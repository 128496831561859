<template>
  <v-container class="pt-0">
    <div class="d-flex flex-row align-center mb-3">
      <div class="agency-title">Agencies</div>
      <div class="title-line ml-3" />
    </div>
    <div class="agency-scroll-section" v-dragscroll.x>
      <agency-card
        v-for="(item, key) in agencies"
        :agency="item"
        :key="key"
        class="ml-3 mb-3"
        :onClicked="onSelectAgency"
        :style="getBackgroundColor(item)"
      />
    </div>
    <router-view />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgencyCard from "@/components/agency/AgencyCard.vue";

export default {
  components: { AgencyCard },
  data() {
    return {
      selectedAgency: null,
      loading: false,
      errorMessage: null,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions("agency", {
      fetchAllAgencies: "fetchAllAgencies",
    }),
    onSelectAgency(item) {
      this.selectedAgency = item;
    },
    getBackgroundColor(item) {
      if (!this.selectedAgency) return "";
      if (item._id == this.selectedAgency._id) {
        return "background-color: #fee3ad";
      }
      return "";
    },
  },
  watch: {
    selectedAgency(newValue) {
      if (newValue != undefined) {
        if (this.$route.params.id != newValue._id) {
          this.$router.push({
            name: "agency-details",
            params: { id: newValue._id },
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters("agency", {
      agencies: "getAllAgencies",
    }),
  },
  mounted() {
    this.loading = true;
    this.fetchAllAgencies()
      .then(() => {
        this.loading = false;
        if (this.$route.params.id) {
          for (var i = 0; i < this.agencies.length; i++) {
            var c = this.agencies[i];
            if (c._id == this.$route.params.id) {
              this.selectedAgency = this.agencies[i];
            }
          }
        } else if (this.agencies.length > 0) {
          this.selectedAgency = this.agencies[0];
          this.$router.push({
            name: "agency-details",
            params: { id: this.agencies[0]._id },
          });
        }
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          this.snackbar = true;
          this.errorMessage = error.response.data.message;
        }
      });
  },
};
</script>
<style scoped>
.agency-title {
  color: #472583;
  font-size: 30px;
  font-weight: bold;
  width: 200px;
  height: 40px;
  min-height: 40px;
  display: flex;
  padding-top: 10px;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}
</style>
