<template>
  <v-card @click="onClicked(agency)">
    <div class="agency-card-content pa-5">
      <v-tooltip bottom v-if="getCompletedInfo">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="red"
            v-text="'mdi-alert'"
            v-bind="attrs"
            v-on="on"
            style="position:absolute; right: 10px; top: 10px;"
          />
        </template>
        <span> Uncompleted profile: Missing {{ getCompletedInfo }} </span>
      </v-tooltip>
      <agency-avatar
        :img="agency.midThumbnail ? agency.midThumbnail : agency.image"
        class="mx-8 mb-3"
        :name="agency.name"
        :size="type == 'Volunteer' ? 120 : 150"
      />
      <span
        class="agency-title my-1 px-2"
        :style="
          type == 'Volunteer'
            ? 'font-size:12px; min-height: 30px;'
            : 'min-height: 50px;'
        "
        >{{ agency.name }}</span
      >
      <v-btn
        block
        style="max-height: 40px; height: 40px;"
        rounded
        color="#472583"
        dark
        v-if="type != 'Volunteer'"
      >
        {{ agency.status }}
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import AgencyAvatar from "./AgencyAvatar.vue";
export default {
  components: { AgencyAvatar },
  props: {
    agency: Object,
    onClicked: Function,
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type",
    }),
    getCompletedInfo() {
      var str = "";
      if (!this.agency.image) str += "Logo";
      if (!this.agency.who_we_are)
        str ? (str += ", Who we are") : (str = "Who we are");
      if (!this.agency.what_we_do)
        str ? (str += ", What we do") : (str = "What we do");
      if (!this.agency.contact_person || !this.agency.contact_title)
        str ? (str += ", Contact Info") : (str = "Contact Info");
      if (!this.agency.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!this.agency.address) str ? (str += ", Address") : (str = "Address");
      if (!this.agency.city) str ? (str += ", City") : (str = "City");
      if (!this.agency.state) str ? (str += ", State") : (str = "State");
      if (!this.agency.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    },
  },
};
</script>
<style>
.agency-title {
  color: #472583;
  font-size: 14px;
  text-align: center;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 15px !important;
  max-height: 20px;
  min-height: 20px;
  height: 20px;
  overflow: hidden;
  align-content: center;
}
.agency-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
</style>
